import React, { useState, useEffect } from "react";
import { useStaticQuery, graphql } from "gatsby";
import {
  createStyles,
  makeStyles,
  withStyles,
  Theme,
  Grid,
  FormControlLabel,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Checkbox,
  CheckboxProps,
  CircularProgress,
  Collapse,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

import {
  InfoBox,
  OpenHourText,
  InfoText,
  StyledAnchor,
  OutlindedFieldContainer,
  StyledButton,
  FormText,
  FileContainer,
  DeleteIcon,
  StyledFormGroup,
  OpenHourIcon,
  EmailIcon,
  MobileIcon,
  MapIcon,
  QrCodeIcon,
  StyledIFrame,
  FieldContainer,
  StyledTextField,
  StyledAlert,
  QRTitle,
  QrCodeBox,
  QrCodeContainer,
} from "./styles";
import PageContainer from "../PageContainer";
import { Breadcrumb, BreadcrumbItem } from "../Breadcrumb";
import MailChimpContainer from "../mailchimp-form";
const Contact = () => {
  const close = () => {};

  return (
    <>
      <Breadcrumb pageTitle="Contact Us">
        <BreadcrumbItem Link="/contact" Text="Contact" />
      </Breadcrumb>

      <Grid container spacing={0}>
        <Grid item xs={12}>
          <StyledIFrame
            title="Gippsland Orthodontics location"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3137.0683990106404!2d145.9398254157443!3d-38.161849879690884!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6b29978d1c2a05b1%3A0x890d78cf54718107!2sGippsland%20Orthodontics!5e0!3m2!1sen!2sau!4v1658926726009!5m2!1sen!2sau"
            frameBorder="0"
            scrolling="no"
          />
        </Grid>
      </Grid>
      <PageContainer>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <OutlindedFieldContainer>
              <MailChimpContainer close={close} />
            </OutlindedFieldContainer>
          </Grid>
          <Grid item xs={12} sm={6}>
            <InfoBox>
              <OpenHourIcon />
              <OpenHourText>
                Monday:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Closed
              </OpenHourText>
              <OpenHourText>
                Tuesday:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;8:30am–5pm
              </OpenHourText>
              <OpenHourText>Wednesday:&nbsp;&nbsp;8.30am-5pm</OpenHourText>
              <OpenHourText>
                Thursday:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;8:30am–5pm
              </OpenHourText>
              <OpenHourText>
                Friday:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Closed
              </OpenHourText>
              <OpenHourText>
                Saturday:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Closed
              </OpenHourText>
              <OpenHourText>
                Sunday:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Closed
              </OpenHourText>
            </InfoBox>
            <InfoBox>
              <StyledAnchor
                href="https://www.google.com/maps/place/Gippsland+Orthodontics/@-38.1613282,145.9370479,17z/data=!3m1!4b1!4m6!3m5!1s0x6b29978d1c2a05b1:0x890d78cf54718107!8m2!3d-38.1613282!4d145.9396282!16s%2Fg%2F11t3tf7xzp?entry=ttu"
                target="_blank"
              >
                <MapIcon />
                <InfoText>
                  83 Albert Street, Warragul, VIC, Australia 3820
                </InfoText>
              </StyledAnchor>
            </InfoBox>
            <InfoBox>
              <StyledAnchor href="mailto:admin@gippslandorthodontic.com.au">
                <EmailIcon />
                <InfoText>admin@gippslandorthodontic.com.au</InfoText>
              </StyledAnchor>
            </InfoBox>
            <InfoBox>
              <StyledAnchor href="tel:03 8580 2489">
                <MobileIcon />
                <InfoText>03 8580 2489</InfoText>
              </StyledAnchor>
            </InfoBox>
          </Grid>
        </Grid>
      </PageContainer>
    </>
  );
};

export default Contact;
